import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IUserPermissionsModel } from 'src/app/Shared/models/AdminUserModel';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private baseURL: string; 
  constructor(private http: HttpClient,
     private appService: AppService) {
         this.baseURL = environment.apiBaseUrl;
 }

  private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public UserPermissions: IUserPermissionsModel;
  public ViewRightFalseMessage = "You do not have rights to View";
  public CreateRightFalseMessage = "You do not have rights to Create New";
  public EditRightFalseMessage = "You do not have rights to Edit";
  public DeleteRightFalseMessage = "You do not have rights to Delete";

  getUserPermissionsForModule(functionId) {
    var permissionsObj;
    if (this.UserPermissions) {
      permissionsObj = {
        UserGroupId: this.UserPermissions.UserGroupId ? this.UserPermissions.UserGroupId : 0,
        Functions: this.UserPermissions.Functions.find(fun => fun.FunctionId == functionId),
        UserGroupRentalTypes: this.UserPermissions.UserGroupRentalTypes ? this.UserPermissions.UserGroupRentalTypes: null,
        UserGroupRoles: this.UserPermissions.UserGroupRoles ? this.UserPermissions.UserGroupRoles: null
      }
    }    
    return permissionsObj
  }

 GetRentalTypesForAMarket(Id: number): Observable<any> {  
  
  return this.http.get<any>(
      `${this.baseURL}/General/GetRentalTypesForAMarket?marketid=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
 }

 getReportUrl(): Observable<any> {
  return this.http.get<any>(
    `${this.baseURL}/General/GetReportUrl`,
    { observe: 'response' })
    .pipe(
      map(res => {
        return res.body as any;
      }),
      catchError(this.handleError)
    );
}

getAllListOfValues(): Observable<any> {
  return this.http.get<any>(
    `${this.baseURL}/General/GetAllListOfValues`,
    { observe: 'response' })
    .pipe(
      map(res => {
        return res.body as any;
      }),
      catchError(this.handleError)
    );
}

getBTCloudLicensing(): Observable<any> {
  return this.http.get<any>(
    `${this.baseURL}/General/GetBTCloudLicensing`,
    { observe: 'response' })
    .pipe(
      map(res => {
        return res.body as any;
      }),
      catchError(this.handleError)
    );
}

GetUserGroupPermissionForUsers(): Observable<IUserPermissionsModel> {
  return this.http.get<any>(
    `${this.baseURL}/User/GetUserGroupPermissionForUsers`,
    { observe: 'response' })
    .pipe(
      map(res => {
        return res.body as any;
      }),
      catchError(this.handleError)
    );
}

getUserDetailsFromUserID(): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url =  `${this.baseURL}/User/GetCurrentUser`;
    return this.http.post<any>(url, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
}

getMarketName(): Observable<any> {
    return this.http.get(
      `${this.baseURL}/General/GetMarketName`,
      { responseType: 'text' }
    ).pipe(
      map(res => res as string),
      catchError(this.handleError)
    );
}

getCompanyName(): Observable<any> {
  return this.http.get(
    `${this.baseURL}/General/GetCompanyName`,
    { responseType: 'text' }
  ).pipe(
    map(res => res as string),
    catchError(this.handleError)
  );
}

  getCustomModeSettings(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/General/GetCustomModeSettings`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  GetWeatherdata(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/DayEnd/GetWeatherdata`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );

  }

  GetTemperatureDetail(date: Date): Observable<any> {
    const formattedDate = date.toISOString().split('T')[0];
    return this.http.get<any>(
      `${this.baseURL}/DayEnd/GetTemperatureDetail?date=${formattedDate}`,
      { observe: 'response' }
    ).pipe(
      map(res => {
        const result = res.body as any;
        return result;
      }),
      catchError(this.handleError)
    );
  }

  SaveTemperatureDetail(TemperatureDetail): Observable<any> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/DayEnd/SaveTemperatureDetail`;
    return this.http.put<any>(url, TemperatureDetail, options)
      .pipe(
        map(res => {
          const response = res as any[];
          return response;
        }),
        catchError(this.handleError)
      );
  }

 private handleError(error: HttpErrorResponse) {
  console.error('server error:', error);
  if (error.error instanceof Error) {
    const errMessage = error.error.message;
   // this.appinsights.logEvent(errMessage);
    return throwError(errMessage);
  }
  return throwError(error || 'Node.js server error');
}

}
