import { AdmAccountingModule } from './admin/adm-accounting/adm-accounting.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RouterDefaultGuard } from './Shared/routing.guard';
import { AuthGuard } from './auth/auth.guard';

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdmModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mt',
    loadChildren: () => import('./admin/adm-maintainance/adm-maintainance.module').then(m => m.AdmMaintainanceModule),
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'mki',
    loadChildren: () => import('./admin/adm-marketinfo/adm-marketinfo.module').then(m => m.AdmMarketInfoModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sec',
    loadChildren: () => import('./admin/adm-security/adm-security.module').then(m => m.AdmSecurityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ac',
    loadChildren: () => import('./admin/adm-accounting/adm-accounting.module').then(m => m.AdmAccountingModule),
    canActivate: [AuthGuard]
  }
  ,
  {
    path: 'sec',
    loadChildren: () => import('./admin/adm-security/adm-security.module').then(m => m.AdmSecurityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ops',
    loadChildren: () => import('./operations/operations.module').then(m => m.OpsModule),
    canActivate: [AuthGuard]   
  },
  {
    path: 'mr',
    loadChildren: () => import('./mobil-rental/mobil-rental.module').then(m => m.MobilRentalModule),
    canActivate: [AuthGuard]   
  },
  {
    path: 'shopper',
    loadChildren: () => import('./shopper-admission/shopper-admission.module').then(m => m.ShopperModule),
  },
  {
    path: 'ops/vendors',
    loadChildren: () => import('./operations/ops-vendors/ops-vendors.module').then(m => m.OpsVendorsModule),
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.loginModule),
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ],
  providers: [
    RouterDefaultGuard
  ]
})
export class AppRoutingModule { }
